.employee-card {
    margin-bottom: 2rem;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
  }
  
  .image-wrapper {
    width: 150px;
    height: auto;
    overflow: visible;
    margin-top: 10%;
  }
  
  .employee-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top;
  }
  
  .employee-divider {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 767px) {
    .image-container {
      justify-content: flex-start;
      padding: 1rem 1rem 0 1rem;
    }
    
    .employee-card .row {
      flex-direction: column;
    }
    
    .employee-card .col-md-9 {
      padding-top: 0;
    }
  }