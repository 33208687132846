.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 80px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  align-items:center;
  text-align: center;
  justify-content: center;
  color: rgb(253, 183, 28, .5);

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-span {
  display: flex;
  justify-content: center;
}

.navbar, .footer {
  background-color: #4d4d4d;
  color: white;
}

.navbar-brand img {
  height: 30px;
  margin-right: 10px;
}

.dropdown-menu {
  background-color: #4d4d4d;
}

.dropdown-item {
  color: white;
}

.dropdown-item:hover {
  background-color: #5a5a5a;
}

.background-image {
  display: none;
}

.custom-navbar {
  background-color: #4d4d4d;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-navbar .navbar-brand,
.custom-navbar .custom-nav-link,
.custom-navbar .nav-link {
  color: white !important;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
}

.custom-navbar .custom-brand {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.custom-navbar .custom-nav-link:hover,
.custom-navbar .nav-link:hover {
  color: #f8f9fa !important;
}

.custom-navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.85)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.custom-navbar .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
}

.custom-navbar .custom-dropdown {
  height: 100%;
  display: flex;
  align-items: center;
}

.custom-navbar .custom-dropdown .dropdown-toggle {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.custom-navbar .dropdown-menu {
  background-color: #4d4d4d;
  margin-top: 0;
}

.custom-navbar .dropdown-item {
  color: white;
  font-size: 1.4rem;
}

.custom-navbar .dropdown-item:hover,
.custom-navbar .dropdown-item:focus {
  background-color: #5a5a5a;
}

.navbar-brand img {
  height: 30px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .custom-navbar .navbar-collapse {
    margin-top: 0.5rem;
  }
  
  .custom-navbar .navbar-nav {
    align-items: flex-start;
  }

  .custom-navbar .custom-dropdown {
    height: auto;
  }

  .custom-navbar .custom-dropdown .dropdown-toggle {
    height: auto;
    padding: 0.5rem 1rem;
  }
}

.navbar-brand img {
  height: 30px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .custom-navbar .navbar-collapse {
    margin-top: 0.5rem;
  }
  
  .custom-navbar .navbar-nav {
    align-items: flex-start;
  }

  .mobile-only-title {
    display: block;
  }
}

.footer {
  background-color: #4d4d4d;
  color: white;
}

.background-image {
  display: none;
}

@media (min-width: 768px) {
  .background-image {
    display: block;
    position: relative;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    margin-top: 8px;
  }

  .background-image::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: rgba(173, 216, 230, 0.7);
  }

  .background-image h1 {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    margin: 0px;
    
    color: #4d4d4d;
    margin: 0;
    z-index: 1;
  }
  .mobile-only-title {
    display: none;
  }
}

.bg-light-blue {
  background-color: #e6f2ff;
}

.profileimage {
  width: 150px;
  max-width: 200px;
  min-width: 100px;
  vertical-align: middle;
}

.logoimage {
  min-height: 100px;
}

.h1HeaderText {
  color:#4d4d4d;
  text-align: center;
}

.mobile-only-title {
  display: none;
}


