.inputPrompt{
    text-align:right;
    align-items: end;
 }
.inputLeft{
    text-align:left;
    align-items:left;
}
.inputRow {
    vertical-align: middle;
}
.buttonShow {
    visibility: visible;
}
.buttonHidden {
    visibility: hidden;
}